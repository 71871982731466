import { FC, useCallback } from 'react';

import { DurationsTagName } from '@vakantiesnl/components/src/__LEGACY__/atoms/DurationsTagName';
import { GroupSelectBox, OptionGroup } from '@vakantiesnl/components/src/__LEGACY__/atoms/GroupSelectBox';
import { OptionProp, SelectBox } from '@vakantiesnl/components/src/__LEGACY__/atoms/SelectBox';
import { QuickSearchHeader } from '@vakantiesnl/components/src/__LEGACY__/molecules/QuickSearchHeader';
import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { Link } from '@vakantiesnl/components/src/atoms/Link';
import { PickDepartureDate } from '@vakantiesnl/components/src/atoms/PickDepartureDate';
import { formattedDurationDaysOptions } from '@vakantiesnl/components/src/utils';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { useParseDurationsFilterConfig } from '@vakantiesnl/services/src/hooks/filter-hooks';
import { useGetCountries } from '@vakantiesnl/services/src/hooks/queries';
import { useQuickSearch } from '@vakantiesnl/services/src/hooks/rest/quick-search/hook';
import { useGlobalFiltersStore } from '@vakantiesnl/services/src/stores';
import { convertDurationsToArray, DEFAULT_DURATIONS_ARRAY } from '@vakantiesnl/services/src/util/durations';
import { Geo, SearchBlock } from '@vakantiesnl/types';

const mapCountryOptions = (country: Geo.MappedDestinationView): OptionProp => ({
	value: country.slug,
	title: country.name,
});

type Props = {
	headerImage: Optional<string>;
	data: SearchBlock;
};

const QuickSearch: FC<Props> = ({ headerImage, data }) => {
	const globalFilters = useGlobalFiltersStore((s) => s.filters);
	const setDurations = useGlobalFiltersStore((s) => s.setDurations);
	const [{ countries, url }, { setQuickSearchCountry, setQuickSearchDepartureDate }] = useQuickSearch();
	const allCountries = useGetCountries();
	const durations = globalFilters.durations
		? convertDurationsToArray(globalFilters.durations)
		: DEFAULT_DURATIONS_ARRAY;

	const microCopies = useMicroCopyContext();
	const allDurationRanges = useParseDurationsFilterConfig(microCopies);
	const durationDaysOptions = formattedDurationDaysOptions(allDurationRanges, microCopies['common.days']);

	const onSelectCountry = useCallback(
		(countrySlug: string) => {
			const selectedCountry = allCountries.data?.find((c) => c.slug === countrySlug);

			if (selectedCountry) {
				const selectedCountries = countries ? [...countries, selectedCountry] : [selectedCountry];
				setQuickSearchCountry(selectedCountries);
			}
		},
		[countries, setQuickSearchCountry, allCountries.data],
	);

	const onDeselectCountry = useCallback(
		(countrySlug: string) => {
			const newSelectedCountries = countries ? countries.filter((val) => val.slug !== countrySlug) : [];

			setQuickSearchCountry(newSelectedCountries);
		},
		[countries, setQuickSearchCountry],
	);

	const onSelectDuration = useCallback(
		(selectedOption: OptionProp) => {
			if (durations) {
				const selectedDurationRange = [...durations, parseInt(selectedOption.value)].sort((a, b) => a - b);
				setDurations(selectedDurationRange);
			}
		},
		[durations, setDurations],
	);

	const onDeselectDuration = useCallback(
		(selectedOption: OptionProp) => {
			if (durations) {
				const selectedDurationRange = durations.filter((item) => item !== parseInt(selectedOption.value));
				setDurations(selectedDurationRange);
			}
		},
		[durations, setDurations],
	);

	const getQuickSearchOptions = useCallback(
		(options: Geo.MappedDestinationView[]): OptionGroup => {
			const quickSearchOptions = [];
			const popularCountries = options.filter((country) => country.isPopular && !country.isDisabled);
			const rest = options.filter((country) => !country.isPopular && !country.isDisabled);

			const popularOption = {
				title: microCopies['common.popularDestinations'],
				options: popularCountries.map(mapCountryOptions),
			};
			const restOption = {
				title: microCopies['common.allDestinations'] as string,
				options: rest.map(mapCountryOptions) as OptionProp[],
			};

			if (popularCountries) quickSearchOptions.push(popularOption);
			if (rest.length) quickSearchOptions.push(restOption);
			return quickSearchOptions;
		},
		[microCopies],
	);

	const optionGroup = getQuickSearchOptions(allCountries.data || []);

	return (
		<QuickSearchHeader
			backgroundImgSrc={headerImage}
			backgroundImgName={headerImage}
			title={data.title}
			quickSearchTitle={data.suggestion}
		>
			<GroupSelectBox
				optionGroup={optionGroup}
				onSelect={onSelectCountry}
				onDeselect={onDeselectCountry}
				microCopies={microCopies}
				placeHolderText={microCopies['quickSearch.placeholder']}
			/>
			<PickDepartureDate
				onChange={setQuickSearchDepartureDate}
				placeholder={microCopies['filters.departureDatePlaceholder']}
			/>
			<SelectBox
				data-cy="searchBarDurationSelect"
				microCopies={microCopies}
				placeHolderText={microCopies['filters.noPreference']}
				options={durationDaysOptions}
				onSelect={onSelectDuration}
				onDeselect={onDeselectDuration}
				defaultValues={durations?.map(String)}
				mode="multiple"
				customTagName={
					durations &&
					durations?.length > 0 && <DurationsTagName microCopies={microCopies} durations={durations} />
				}
				listHeight={475}
			/>
			<Link href={url.href} as={url.as}>
				<Button variant={data.cta.variant} trailingIcon="arrowRight" asText>
					{data.cta.title}
				</Button>
			</Link>
		</QuickSearchHeader>
	);
};

export default QuickSearch;
